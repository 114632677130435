import { useState } from 'react';
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { DataGrid } from "@mui/x-data-grid";

import AedasLoading from "../Shared/AedasLoading/AedasLoading";

import "../../Components/Static/Iventory/inventory.css";

export const ListEditItems = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  if (isLoading) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center">
        <AedasLoading></AedasLoading>
      </div>
    );
  }

  return (
    <div className="tableContainer">
      <DataGrid 
        rows={props.rows}
        columns={props.columns}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        pagination
        autoHeight={true}
      />
    </div>
  )
}
