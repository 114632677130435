import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";

import {
  GridColDef,
  GridPreProcessEditCellProps,
  GridRenderEditCellParams,
  useGridApiContext,
} from "@mui/x-data-grid";
import DataGridEditabled from "../../Shared/DataGridEditabled/DataGridEditabled";
import { Checkbox } from "@mui/material";

import AedasLoading from "../../Shared/AedasLoading/AedasLoading";
import {
  addParther,
  deleteParther,
  getAllParther,
  updateParther,
} from "../../../Service/PartherService/PartherService";
import { deleteInventory } from "../../../Service/InventoryService/InventoryService";

import "./Partner.css";

let schema = yup.object().shape({
  name: yup.string().required(),
});

function Partner(props: any) {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const [partner, setPartner] = useState([]);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    if (isLoading) {
      getAllParther().then((response: any) => {
        setPartner(response.data);
        setIsLoading(false);
      });
    }
  }, [isLoading]);
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 9, hide: true },
    {
      field: "name",
      headerName: "Razón social",
      width: 200,
      editable: true,
      flex: 2,
    },
    {
      field: "cif",
      headerName: "CIF",
      width: 200,
      editable: true,
      flex: 1,
    },
    {
      field: "is_part",
      headerName: "Partner",
      renderCell: (params: GridRenderEditCellParams) => {
        return <>{params.value === true ? "Si" : "No"}</>;
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return <CustomEditComponent {...params}></CustomEditComponent>;
      },
      width: 80,
      editable: true,
      flex: 1,
    },
    {
      field: "is_maker",
      headerName: "Fabricante",
      renderCell: (params: GridRenderEditCellParams) => {
        return <>{params.value === true ? "Si" : "No"}</>;
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return <CustomEditComponent {...params}></CustomEditComponent>;
      },
      width: 80,
      editable: true,
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      editable: true,
      flex: 1,
    },
    {
      field: "telephone",
      headerName: "Teléfono",
      width: 200,
      editable: true,
      flex: 1,
    },
  ];

  const handleSave = (data: any) => {
    if (data.news.length > 0) {
      handleCreate(data.news);
    }
    if (data.edited.length > 0) {
      handleUpdate(data.edited);
    }
    if (data.removed.length > 0) {
      handleRemove(data.removed);
    }
  };

  useEffect(() => {}, [isLoading]);

  useEffect(() => {
    setIsLoading(true);
  }, [isSaving]);

  const handleCreate = (partners: any) => {
    let aux: any = [];
    partners.map((p: any) => {
      aux.push(addParther(p));
    });
    Promise.all(aux).then(() => {
      setIsLoading(true);
      toast.success("Guardado");
    });
  };

  const handleUpdate = (partners: any) => {
    let aux: any = [];
    partners.map((p: any) => {
      aux.push(updateParther(p));
    });
    Promise.all(aux).then(() => {
      setIsLoading(true);
      toast.success("Guardado");
    });
  };
  const handleRemove = (partners: any) => {
    let aux: any = [];
    partners.map((p: any) => {
      aux.push(deleteParther(p));
    });
    Promise.all(aux).then(() => {
      setIsLoading(true);
      toast.success("Borrado");
    });
  };

 
  return (
    <div className="h-100 m-4">
      <div className='main-container p-5'>
        <h4 className="main-title mb-3 mt-0">Partner o Fabricante</h4>
        {isLoading ? (
            <div className="w-100 h-100 d-flex justify-content-center">
              <AedasLoading />
            </div>
        ) : (
          <DataGridEditabled
            rows={partner}
            columns={columns}
            title={""}
            handleSave={handleSave}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
          ></DataGridEditabled>
        )}
      </div>
    </div>
  );
}

export default Partner;

const CustomEditComponent = (props: any) => {
  const { id, value, field } = props;
  const [active, setActive] = useState(value);

  const apiRef = useGridApiContext();

  const handleChange = (event: any, newValue: any | null) => {
    setActive(!active);
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return <Checkbox checked={active} onChange={handleChange} />;
};
