import * as React from "react";
import moment from "moment";
import AedasLoading from "../../Shared/AedasLoading/AedasLoading";
import { getAllAccessType } from "../../../Service/AccessTypeService/AccessTypeService";
import { getAllHost } from "../../../Service/HostService/HostService";
import { getAllParther } from "../../../Service/PartherService/PartherService";
import { getAllLicenceTypeService } from "../../../Service/LicenceTypeService/LicenceTypeService";


function InventoryElementView(props: any) {
  const [IsLoading, setIsLoading] = React.useState(true);
  const [access, setAccess] = React.useState<any>(null);
  const [partner, setPartner] = React.useState<any>(null);
  const [host, setHost] = React.useState<any>(null);
  const [licence, setLicence] = React.useState<any>(null);

  React.useEffect(() => {
    let aux: any = [];
    if (IsLoading) {
      getAllAccessType().then((response: any) => {
        setAccess(response.data);
      });
      getAllHost().then((response: any) => {
        setHost(response.data);
      });
      getAllParther().then((response: any) => {
        setPartner(response.data);
      });
      getAllLicenceTypeService().then((response: any) => {
        setLicence(response.data);
      });
    }
  }, [IsLoading]);

  React.useEffect(() => {
    if (access && licence && partner && host) {
      setIsLoading(false);
    }
  }, [access, licence, partner, host]);

  if (IsLoading) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center">
        <AedasLoading></AedasLoading>
      </div>
    );
  }
  
  return (
    <div>
      <InventoryElementViewInner
          {...props}
          partner={partner}
          host={host}
          access={access}
          licence={licence}
          itemType={props.itemType}
        />
    </div>
  );
}

export default InventoryElementView;

function InventoryElementViewInner(props: any) {
  const { partner, host, access, licence, itemType } = props;

  const levelOptions = [
		{	
			id: 0,
			level: "Baja",
		},
		{	
			id: 1,
			level: "Moderada/Media",
		},
		{	
			id: 2,
			level: "Alta",
		},
	];

	const confidOptions = [
		{	
			id: 0,
			level: "Pública",
		},
		{	
			id: 1,
			level: "Interna",
		},
		{	
			id: 2,
			level: "Confidencial",
		},
		{	
			id: 3,
			level: "Reservada",
		},
		{	
			id: 4,
			level: "Secreta",
		}	
	];

  const handleOpenFile = () => {
    console.log(props.selectedRow);

    if (props.selectedRow) {
      let pdfWindow = window.open("");
      pdfWindow?.document.write(
        `<iframe
            title='${props.selectedRow.licenses_names}'
            width='100%'
            height='100%'
            src='data:application/pdf;base64, ${encodeURI(props.selectedRow.licenses_files)} '
        ></iframe>`
      )
    }   
  }

  return (
    <div className="row ">
      <div className="d-flex justify-content-between p-2">
        <div className="col">
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Nombre
          </h2>
          <h3 style={{ fontSize: "15px" }}>{props.selectedRow.app_name}</h3>
        </div>

        <div className="col">
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Descripción
          </h2>
          <h3 style={{ fontSize: "15px" }}>{props.selectedRow.description}</h3>
        </div>

        <div className="col"></div>
      </div>
      
      <div className="d-flex justify-content-between p-2">
        <div className="col">
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Web
          </h2>
          <h3 style={{ fontSize: "15px" }}>
            {props.selectedRow.website && props.selectedRow.website != ""
              ? props.selectedRow.website
              : "No hay datos"}
          </h3>
        </div>

        <div className="col">
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Tipo de acceso
          </h2>
          <h3 style={{ fontSize: "15px" }}>
            {access.find((a: any) => a.id === props.selectedRow.id_access)
              ?.name ?? "No hay datos"}
          </h3>
        </div>
        
        <div className="col">
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Alojamiento
          </h2>
          <h3 style={{ fontSize: "15px" }}>
            {host.find((a: any) => a.id === props.selectedRow.id_host)
              ?.name ?? "No hay datos"}
          </h3>
        </div>
      </div>

      <div className="d-flex justify-content-between p-2">
        <div className="col">
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Partner
          </h2>
          <h3 style={{ fontSize: "15px" }}>
            {partner.find((a: any) => a.id === props.selectedRow.id_partner)
              ?.name ?? "No hay datos"}
          </h3>
        </div>

        <div className="col">
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Fabricante
          </h2>
          <h3 style={{ fontSize: "15px" }}>
              {partner.find((a: any) => a.id === props.selectedRow.maker)
              ?.name ?? "No hay datos"}
          </h3>
        </div>

        <div className="col">
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Número de proveedor
          </h2>
          <h3 style={{ fontSize: "15px" }}>
            {props.selectedRow.supplier && props.selectedRow.supplier != ""
              ? props.selectedRow.supplier
              : "No hay datos"}
          </h3>
        </div>
      </div>

      { itemType === 'licencia' ? (
        <div className="d-flex justify-content-between p-2">
          <div className="col">
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Tipo de licencia
          </h2>
          <h3 style={{ fontSize: "15px" }}>
            {licence.find((a: any) => a.id === props.selectedRow.id_licence)
              ?.name ?? "No hay datos"}
          </h3>
        </div>
        
        
        <div className="col">
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Número de licencia
          </h2>
          <h3 style={{ fontSize: "15px" }}>
            {props.selectedRow.licence_number &&
            props.selectedRow.licence_number != ""
              ? props.selectedRow.licence_number
              : "No hay datos"}
          </h3>
        </div>

        <div className="col">
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Estado licencia
          </h2>
          <h3 style={{ fontSize: "15px" }}>
            {props.selectedRow.active ? "Activa" : "Inactiva"}
          </h3>
        </div>


        </div> ) : <></> 
      } 

      <div className="d-flex justify-content-between p-2">
        <div className="col">
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Número de accesos
          </h2>
          <h3 style={{ fontSize: "15px" }}>
            {props.selectedRow.accesses_number ?? "No hay datos"}
          </h3>
        </div>

        <div className="col">
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Accesos consumidos
          </h2>
          <h3 style={{ fontSize: "15px" }}>
            {props.selectedRow.consumed_users ?? "No hay datos"}
          </h3>
        </div>

        <div className="col">
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Confidencialidad
          </h2>
          <h3 style={{ fontSize: "15px" }}>
            {props.selectedRow.confid ? confidOptions.find((level: any) => level.id === props.selectedRow.confid)?.level : "No hay datos"}

          </h3>
        </div>
      </div>  
      
      <div className="d-flex justify-content-between p-2">
        <div className="col">
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Fecha de compra
          </h2>
          <h3 style={{ fontSize: "15px" }}>
            {props.selectedRow.purchase_date
              ? moment(props.selectedRow.purchase_date).format("DD/MM/YYYY")
              : "No hay datos"}
          </h3>
        </div>

        <div className="col">
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Partida presupuestaria
          </h2>
          <h3 style={{ fontSize: "15px" }}>
            {props.selectedRow.budget && props.selectedRow.budget != ""
              ? props.selectedRow.budget
              : "No hay datos"}
          </h3>
        </div>

        <div className="col">
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Email
          </h2>
          <h3 style={{ fontSize: "15px" }}>
            {props.selectedRow.email && props.selectedRow.email != ""
              ? props.selectedRow.email
              : "No hay datos"}
          </h3>
        </div>     
      </div>
      
      <div className="d-flex justify-content-between p-2">
        <div className="col">
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Criticidad para el negocio
          </h2>
          <h3 style={{ fontSize: "15px" }}>
            {props.selectedRow.crits ? levelOptions.find((level: any) => level.id === props.selectedRow.crits)?.level : "No hay datos"}
          </h3>
        </div>

        <div className="col">
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Integridad
          </h2>
          <h3 style={{ fontSize: "15px" }}>
            {props.selectedRow.integrity ? levelOptions.find((level: any) => level.id === props.selectedRow.integrity)?.level : "No hay datos"}
          </h3>
        </div>

        <div className="col">
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Disponibilidad
          </h2>
          <h3 style={{ fontSize: "15px" }}>
            {props.selectedRow.dispo ? levelOptions.find((level: any) => level.id === props.selectedRow.dispo)?.level : "No hay datos"}
          </h3>
        </div>
      </div>      
      
      <div className="d-flex justify-content-between p-2">
        <div className="col">
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Dpto. Responsable de Negocio
          </h2>
          <h3 style={{ fontSize: "15px" }}>
            {props.selectedRow.departmen ?? "No hay datos"}
          </h3>
        </div>

        <div className="col">
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Product Owner
          </h2>
          <h3 style={{ fontSize: "15px" }}>
            {props.selectedRow.product_own ?? "No hay datos"}
          </h3>
        </div>

        <div className="col">
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Business Owner
          </h2>
          <h3 style={{ fontSize: "15px" }}>
            {props.selectedRow.buss_own ?? "No hay datos"}
          </h3>
        </div>

      </div> 

      <div className="d-flex justify-content-between p-2">
        <div className="col">
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Fecha de vencimiento
          </h2>
          <h3 style={{ fontSize: "15px" }}>
            {props.selectedRow.expiration_day
              ? moment(props.selectedRow.expiration_day).format("DD/MM/YYYY")
              : "Sin vencimiento"}
          </h3>
        </div>

        <div className="col">
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Aviso de caducidad
          </h2>
          <h3 style={{ fontSize: "15px" }}>
            {props.selectedRow.advise_days ? `${props.selectedRow.advise_days} días antes` :"No hay datos"}
          </h3>
        </div>

        { itemType === 'licencia' ? (
          <div className="col">
            {/* <Button 
              onClick={handleOpenFile}
              color="primary"
              className="Button"
              >
                contrato de suministro
            </Button> */}
        </div>
          ): <div className="col"></div>
        } 
      </div>      

      <div className="d-flex justify-content-between p-2">
        <div className="col">
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Comentario
          </h2>
          <h3 style={{ fontSize: "15px" }}>
            {props.selectedRow.comment && props.selectedRow.comment != ""
              ? props.selectedRow.comment
              : "No hay datos"}
          </h3>
        </div>      
      </div>     
    </div>
  );
}
