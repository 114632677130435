import * as React from "react";
import moment from "moment";
import { toast } from "react-toastify";

import { useHistory } from "react-router-dom";

import { DataGrid, GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import Button from "@mui/material/Button";

import CustomModal from "../../Shared/ModalCustom/CustomModal";
import AedasLoading from "../../Shared/AedasLoading/AedasLoading";
import InventoryElementView from "./InventoryElementView";

import { getAllInventory, deleteInventory } from "../../../Service/InventoryService/InventoryService";
import { getAllLicenceTypeService } from "../../../Service/LicenceTypeService/LicenceTypeService";
import { getAllHost } from "../../../Service/HostService/HostService";
import { getAllAccessType } from "../../../Service/AccessTypeService/AccessTypeService";
import { getAllParther } from "../../../Service/PartherService/PartherService";

import "./inventory.css";

function Inventory(props: any) {
  const [openModalView, setOpenModalView] = React.useState(false);

  const [allItems, setAllItems] = React.useState<any>(null);
  const [data, setData] = React.useState<any>(null);
  const [columns, setColumns] = React.useState<any>([]);
  const [host, setHost] = React.useState<any>(null);
  const [licence, setLicence] = React.useState<any>(null);
  const [selectedRow, setSelectedRow] = React.useState<any>(null);
  const [access, setAccess] = React.useState<any>(null);
  const [partner, setPartner] = React.useState<any>(null);
  const [selectionModel, setSelectionModel] =
    React.useState<GridSelectionModel>([]);

  const [isLoading, setIsLoading] = React.useState(true);
  const [ openDeleteModal, setDeleteOpenModal ] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
    if (isLoading) {
      getAllInventory().then((response: any) => {
        setAllItems(response.data);
      });
      getAllHost().then((response: any) => {
        setHost(response.data);
      });
      getAllLicenceTypeService().then((response: any) => {
        setLicence(response.data);
      });
      getAllAccessType().then((response: any) => {
        setAccess(response.data);
      });
      getAllParther().then((response: any) => {
        setPartner(response.data);
      });

      setColumns(licenceColumns)
    }
  }, [isLoading]);

  React.useEffect(() => {
    if ( !isLoading ) {
      if ( props.itemType === 'activo') {
        setData(allItems.filter((item: any) => item.activo === true));
        setColumns(activesColumns);
      } else {
        setData(allItems.filter((item: any) => item.req_licence === true));
        setColumns(licenceColumns);
      }
    }
  }, [isLoading, props.itemType])

  React.useEffect(() => {
    if (allItems && licence && host && partner) {
      setIsLoading(false);
    }
  }, [licence, allItems, host, partner]);

  const licenceColumns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90, hide: true },
    {
      field: "app_name",
      headerName: "Aplicación",
      width: 300,
      flex: 2,
    },
    {
      field: "id_licence",
      headerName: "Tipo de Licencia",
      width: 300,
      flex: 1,
      renderCell(params) {
        return <>{licence.find((h: any) => h.id === params.value)?.name}</>;
      },
    },
    {
      field: "id_host",
      headerName: "Host",
      width: 300,
      flex: 1,
      renderCell(params) {
        return <>{host.find((h: any) => h.id === params.value)?.name}</>;
      },
    },
    {
      field: "id_partner",
      headerName: "Partner",
      width: 300,
      flex: 1,
      renderCell(params) {
        return <div className="MuiDataGrid-cellContent">{partner.find((h: any) => h.id === params.value)?.name}</div>;
      },
    },
    {
      field: "expiration_day",
      headerName: "Fecha de vencimiento",
      width: 300,
      flex: 1,
      renderCell(params) {
        if (params.value === "" || !params.value) {
          return <>Sin vencimiento</>;
        }
        return <>{moment(params.value).format("DD/MM/YYYY")}</>;
      },
    },
    {
      field: "button",
      headerName: "Acciones",
      width: 300,
      flex: 2,
      renderCell(params) {
        return (
          <div style={{display: "flex"}}>
            <Button
              onClick={() => {
                setSelectedRow(params.row);
                setDeleteOpenModal(true)
              }}
              color="primary"
              className="reject_Button table-button"
              id={`edit-button-${params.row.id}`}
              key={`delete-${params.row.id}`}
            >
              borrar
            </Button>

            <Button
              onClick={ (e: any) => history.push(`/editar/licencia/${params.row.id}`)}
              color="primary"
              className="Button table-button"
              id={`edit-button-${params.row.id}`}
              key={`edit${params.row.id}`}
            >
              editar
            </Button>
          </div>
        )
      },
      editable: false,
    }
  ];

  const activesColumns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90, hide: true },
    {
      field: "app_name",
      headerName: "Nombre del activo",
      width: 300,
      flex: 2,
    },
    {
      field: "departmen",
      headerName: " Dpto. Resp. de negocio",
      width: 300,
      flex: 2,
    },
    {
      field: "buss_own",
      headerName: "Business Owner",
      width: 300,
      flex: 1,
    },
    {
      field: "product_own",
      headerName: "Product Owner",
      width: 300,
      flex: 1,
    },
    {
      field: "id_partner",
      headerName: "Partner",
      width: 300,
      flex: 1,
      renderCell(params) {
        return <div className="MuiDataGrid-cellContent">{partner.find((h: any) => h.id === params.value)?.name}</div>;
      },
    },
    {
      field: "button",
      headerName: "Acciones",
      width: 300,
      flex: 2,
      renderCell(params) {
        return (
          <div style={{display: "flex"}}>
            <Button
              onClick={() => {
                setSelectedRow(params.row);
                setDeleteOpenModal(true)
              }}
              color="primary"
              className="reject_Button table-button"
              id={`edit-button-${params.row.id}`}
              key={`delete-${params.row.id}`}
            >
              borrar
            </Button>

            <Button
              onClick={ (e: any) => history.push(`/editar/activo/${params.row.id}`)}
              color="primary"
              className="Button table-button"
              id={`edit-button-${params.row.id}`}
              key={`edit${params.row.id}`}
            >
              editar
            </Button>
          </div>
        )
      },
      editable: false,
    }
  ];

  const handleRemove = () => {
    if ( selectedRow ) {
      deleteInventory(selectedRow)
        .then(() => setIsLoading(true))
        .then(() => toast.success("Borrado"))
        .then(() => setDeleteOpenModal(false))
    }
 }

 const handleOpenModal = (params: any) => {
  if (params.field !== 'button') {
    setOpenModalView(true);
    setSelectedRow(params.row);
  } else return
 }

  if (isLoading) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center">
        <AedasLoading/>
      </div>
    );
  }

  return (
    <div className='h-100 m-4'>
      <div className='main-container p-5' id='home'>
        <h4 className='main-title mb-3 mt-0'>{ props.itemType === 'licencia' ? 'Licencias' : 'Activos Tecnológicos' }</h4>
       
        <CustomModal
					size="lg"
					show={openDeleteModal}
					onChange={() => {
						setDeleteOpenModal(!openDeleteModal);
					}}
					title="Confirmar borrado"
					body={
						<div>
							<div className="d-flex justify-content-center">
								<h5>Confirmo el borrado de este elemento</h5>
							</div>
							<div className="d-flex justify-content-center">
								<Button onClick={() => handleRemove()} className="reject_Button">
									Confirmar borrado
								</Button>
							</div>
						</div>
					}
				/>

        <CustomModal
          size="xl"
          show={openModalView}
          onChange={() => {
            setOpenModalView(!openModalView);
          }}
          title={`Información ${props.itemType === 'licencia' ? 'de la Licencia' : 'del Activo Tecnológico' }`}
          body={
            <InventoryElementView
              selectedRow={selectedRow}
              setOpenModalView={setOpenModalView}
              setIsLoading={setIsLoading}
              itemType={props.itemType}
            />
          }
        />
      
        <div
          style={{
            background: "white",
            borderRadius: "5px",
            paddingTop: "2px",
          }}
        >
          <div className="tableContainer">
            <DataGrid
              rows={data}
              columns={columns}
              rowsPerPageOptions={[10, 20, 50, 100]}
              pagination
              onCellClick={handleOpenModal}
              autoHeight={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inventory;
