import { useFormik } from "formik";
import * as React from "react";
import { login } from "../../../Service/AuthService/AuthService";
import LoadingButton from "@mui/lab/LoadingButton";
import * as yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";

import "./Login.css";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userLogin } from "../../../Redux/User/UserAction";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Introduzca un email valido. Ej: ejemplo@gmail.com")
    .required("Este campo es requerido"),
  password: yup
    .string()
    .min(8, "La contraseña debe ser de al menos 8 caracteres")
    .required("La contraseña es requerida"),
});

export default function LoginForm(props: any) {
  const history = useHistory();
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: null,
    onSubmit: async (values) => {
      // setIsLoading(true);
      // let user = await login(values.email, values.password);
      // if (user) {
      //   dispatch(userLogin(JSON.parse(user)));
      //   history.push("/");
      // }
      // setIsLoading(false);
      instance
        .loginRedirect(loginRequest)
        .then((response) => console.log("login", response))
        .catch((e) => {
          console.error(e);
        });
    },
  });
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div></div>

        <LoadingButton
          color="primary"
          style={{
            backgroundColor: "var(--blue-lis)",
            fontFamily: "var(--lis-title)",
          }}
          variant="contained"
          fullWidth
          loadingIndicator={<CircularProgress size={16} />}
          loading={isLoading}
          type="submit"
        >
          Entrar
        </LoadingButton>
      </form>
    </>
  );
}
