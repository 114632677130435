import * as React from "react";
import MainListItems from "../LisHeaderProvaider/listItems";
import IconButton from "@mui/material/IconButton";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./Header.css";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../../Redux/User/UserAction";
import { useMsal } from "@azure/msal-react";
function Header(props: any) {
  const { menu, setIsOpen, open, setisLogin } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const handleOpenClose = () => {
    setIsOpen(!open);
  };
  const user = useSelector((state: any) => state.userReducer.user);
  const position = useSelector((state: any) => state.userReducer.position);
  const { instance, accounts } = useMsal();
  const handleLogOut = () => {
    localStorage.removeItem("aedas_user");
    dispatch(userLogout());
    instance.logoutRedirect({
      account: accounts[0],
    });
  };

  return (
    <div
      style={{
        width: open ? "300px" : "70px",
        zIndex:10,
        height: "100%",
        backgroundColor: "white",
        position: "fixed",
        overflow: "hidden",
        boxShadow: "rgb(216 216 216 / 40%) 1px 1px 10px"
      }}
    >
      <div className="d-flex justify-content-center w-100">
        <img
          src="/Assets/img/LogoAedasSmall.svg"
          style={{ height: "70px" }}
        ></img>
        {open ? (
          <div className="d-flex align-items-center">
            <h1 className="appName">Inventario</h1>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="openCloseButton_container">
        <IconButton onClick={handleOpenClose} className="openCloseButton">
          {open ? (
            <ArrowBackIosIcon></ArrowBackIosIcon>
          ) : (
            <ArrowForwardIosIcon></ArrowForwardIosIcon>
          )}
        </IconButton>
      </div>

      <MainListItems
        setOpen={setIsOpen}
        open={open}
        menu={menu}
      ></MainListItems>
      {open ? (
        <div className="personHeader">
          <hr style={{ margin: "20px" }}></hr>

          <div className=" p-2 col-2 w-100 d-flex justify-content-between ">
            <div className="d-flex justify-content-center">
              <img
                className="imgPersonHeader"
                src={
                  process.env.REACT_APP_API_BACKEND +
                  "/" +
                  user?.employee.url_img
                }
              ></img>
            </div>
            <div className="row d-flex align-items-center">
              <div>
                <h1
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    fontFamily: "var(--lis-title)",
                  }}
                >
                  {user?.employee.display_name}
                </h1>
                <h2
                  style={{
                    textAlign: "center",
                    fontSize: "10px",
                    fontFamily: "var(--lis-title)",
                    color: "grey",
                  }}
                >
                  {position}
                </h2>
              </div>
            </div>
            <div className="row ">
              <div className="d-flex aling-items-center">
                <IconButton
                  onClick={() => {
                    handleLogOut();
                  }}
                >
                  <PowerSettingsNewIcon></PowerSettingsNewIcon>
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="personHeader ">
          <div className=" p-2 col-12 w-100 row">
            <div className="d-flex justify-content-center">
              <img
                className="imgPersonHeaderSmall"
                src={
                  process.env.REACT_APP_API_BACKEND +
                  "/" +
                  user?.employee.url_img
                }
              ></img>
            </div>

            <div className="row">
              <div className="d-flex justify-content-center">
                <IconButton
                  onClick={() => {
                    handleLogOut();
                  }}
                >
                  <PowerSettingsNewIcon></PowerSettingsNewIcon>
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
